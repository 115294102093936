<template>
  <v-dialog
      v-model="dialog"
      max-width="400"
      overlay-color="primary"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase primary--text font-weight-bold today-medium">
        Ingresar información
      </v-card-title>

      <div class="px-14 pb-6 mt-4">
        <v-form
            ref="form"
            lazy-validation
            @submit.prevent="singUp">

          <v-select
              v-model="document_type"
              :items="document_types"
              item-text='value'
              item-value='key'
              :rules="[v => !!v || 'Selecciona el campo']"
              label="Tipo de documento"
              required
              outlined
              dense
              color="primary"
              class="select-field"
          ></v-select>

          <v-text-field
              v-model="document_id"
              :rules="[v => !!v || 'Ingresa la información']"
              type="number"
              label="Número de documento"
              required
              outlined
              dense
              color="primary"
              class="input-field"
          ></v-text-field>

          <v-icon color="primary">mdi-ticket-percent-outline</v-icon> <a href="#" @click="promoCode = !promoCode" class="today-bold" style="font-size: 0.65rem !important;"  color="primary">Si tienes un código promocional ingrésalo aquí</a>

          <v-text-field v-if="promoCode"
                        v-model="coupon"
                        label="Ingresa el código para redimirlo"
                        class="input-field"
          ></v-text-field>

          <a href="https://vardiseguros.com.co/static/uploads/613b959d-eb39-4cad-b1e4-b9a9344194af.pdf" target="_blank" class="terms-link mt-3 today-bold" style="font-size: 0.65rem !important;" color="primary">Al ingresar estas aceptando los términos y condiciones, políticas de privacidad y tratamiento de datos personales</a>

          <v-layout justify-center pt-4>
            <v-card-actions>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  type="submit"
                  class="px-12 text-capitalize vardi-button">
                Continuar
              </v-btn>
            </v-card-actions>
          </v-layout>

        </v-form>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import {locals} from "@/helpers/locals";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";

export default{
  name: 'onboarding-modal',
  props: ['dialog'],
  data() {
    return {
      coupon: '',
      document_id: '',
      promoCode: false,
      loading: false,
      settings: null,
      document_types: [],
      document_type: ''
    }
  },
  methods: {
    singUp() {
      let validate = this.$refs.form.validate();
      if(validate) {
        this.loading = true;

        api.post(constants.endPoints.isRegistered, {
          document_type: this.document_type,
          document_id: this.document_id,
          coupon: this.coupon
        }).then(function (response) {
          this.loading = false;
          let exist_user = response.data.data.exist_user;
          console.log('post singUp', response, exist_user);

          let user = {
            document_type: this.document_type,
            document_id: this.document_id,
            coupon: this.coupon
          };

          locals.setLocal('user', JSON.stringify(user));

          if(exist_user) {
            console.log('Existe', exist_user);
            user.login = true;
            locals.setLocal('user', JSON.stringify(user));

            this.$router.push('/preparate-para-selfie');
          } else {
            this.$router.push('/registro-de-documento');
          }
        }.bind(this)).catch(function (error) {
          let errors = error.response.data.errors;
          console.log('errors: ', error);
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;

        }.bind(this))
      }

    }
  },
  created() {
    this.settings = locals.getLocal('settings');
    this.settings.config.forEach((value) => {
      if(value.key == 'document_types') {
        this.document_types = value.value;
      }
    });
  }
}
</script>

<style scoped>
.v-application .text-caption {
  letter-spacing: 0 !important;
  line-height: 0.8rem !important;
  font-size: 0.65rem !important;
}

.terms-link {
  display: block;
}

.v-card__title {
  word-break: inherit;
}

.home-title {
  font-size: 2.5em;
  line-height: 1;
}

.card-subtitle {
  font-size: 22px;
  line-height: 1;
}

.home-image {
  height: 100px;
}

@media (max-width: 600px) {
  .home-title {
    font-size: 2em;
    line-height: 1;
  }

  .card-subtitle {
    font-size: 18px;
    line-height: 1;
  }

  .home-image {
    height: 140px;
  }
}
</style>