<template>
  <div class="home-page fill-height">

    <v-row align="center" justify="center" class="fill-height primary" style="margin-top: 0 !important;">

      <v-card
          class="mx-auto"
          max-width="400"
          rounded="xl">
        <v-container>

          <v-row align="center" justify="center" class="mt-2">
            <v-col align="center" justify="center" cols="12" md="12" lg="12">
              <v-img
                  alt="Aliados QR"
                  src="@/assets/logo_biocredit.png"
                  width="160"
              />
            </v-col>

            <v-col cols="12" md="12" lg="12">

              <div class="px-10 pb-6">
                <v-form
                    ref="form"
                    lazy-validation
                    @submit.prevent="login">

                  <v-text-field
                      v-model="email"
                      :rules="[v => !!v || 'Ingresa el usuario']"
                      type="text"
                      label="Usuario"
                      required
                      outlined
                      dense
                      color="primary"
                      class="input-field"
                  ></v-text-field>

                  <v-text-field
                      ref="password"
                      v-model="password"
                      :rules="[v => !!v || 'Ingresa la contraseña']"
                      type="password"
                      label="Contraseña"
                      required
                      outlined
                      dense
                      color="primary"
                      class="input-field"
                  ></v-text-field>

                  <a href="#" @click="dialogResetPassword = true" class="text-center terms-link mt-2 today-bold" style="font-size: 0.75rem !important; color: #000000 !important;">¿Olvido su contraseña?</a>

                  <v-layout justify-center>
                    <v-card-actions>
                      <v-btn
                          :loading="loading"
                          :disabled="loading"
                          @click="login"
                          color="primary"
                          type="submit"
                          :rounded="true"
                          class="px-15 text-capitalize vardi-button">
                        Ingresar
                      </v-btn>
                    </v-card-actions>
                  </v-layout>

                </v-form>
              </div>


            </v-col>
          </v-row>

        </v-container>
      </v-card>

    </v-row>


    <v-dialog
        v-model="dialogResetPassword"
        max-width="400"
        overlay-color="primary"
    >
      <v-card class="pa-4 text-center">


        <v-card-title class="justify-center text-uppercase font-weight-bold today-medium">
          Restaurar contraseña
        </v-card-title>

        <h3 class="text-justify primary--text today-light line-height-1 px-12">Ingresa el correo electronico para restaurar la contraseña</h3>


        <div class="px-12 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="resetPassword()">

            <v-text-field
                v-model="changeEmail"
                :rules="[v => !!v || 'Ingresa el email']"
                type="text"
                label="Email"
                required
                outlined
                dense
                color="primary"
                class="input-field"
            ></v-text-field>

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12 text-capitalize vardi-button">
                  Enviar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>
  </div>

</template>

<script>
import OnboardingModal from "@/components/Layouts/OnboardingModal";
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";


export default {
  name: 'home-app',
  components: {OnboardingModal, LeftSideImage},
  data() {
    return {
      email: null,
      password: '',
      changeEmail: null,

      document_type: '',
      document_id: '',
      coupon: '',
      dialog: false,
      promoCode: false,
      loading: false,
      settings: null,
      document_types: [],
      snackbar: false,
      timeout: 3000,
      snackbarMessage: '',
      dialogResetPassword: false,
    }
  },
  methods: {
    login() {

      let validate = this.$refs.form.validate();
      console.log('validate', validate);
      if(validate) {

        this.loading = true;

        api.post(constants.endPoints.login, {
          email: this.email,
          password: this.password
        }).then(function (response) {

          console.log('post login', response);
          this.loading = false;

          let user = response.data.user;
          let token = response.data.access_token;
          let company = response.data.company;

          locals.setLocal('user', JSON.stringify(user));
          locals.setLocal('token', JSON.stringify(token));
          locals.setLocal('company', JSON.stringify(company));

          this.$router.push('/productos');

          this.$refs['password'].error = false;
          this.$refs['password'].errorMessages = [];

        }.bind(this)).catch(function (error) {
          let errors = error.response.data;
          console.log('errors: ', error, error.response);
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          //this.snackbar = true;
          this.loading = false;

          this.$refs['password'].error = true;
          this.$refs['password'].errorMessages = ['La contraseña es incorrecta'];

        }.bind(this))


      }

    },
    singUp() {

      //this.$router.push('/autenticar-usuario');

      /*let validate = this.$refs.form.validate();
      if(validate = 0) {
        this.loading = true;

        this.$router.push('/simular-financiacion');

        api.post(constants.endPoints.isRegistered, {
          document_type: this.document_type,
          document_id: this.document_id
        }).then(function (response) {

              console.log('post singUp', response);
              this.loading = false;
              let exist_user = response.data.data.exist_user;

              let user = {
                document_type: this.document_type,
                document_id: this.document_id,
              };

              locals.setLocal('user', JSON.stringify(user));

              if(exist_user) {
                console.log('Existe', exist_user);
                user.login = true;
                locals.setLocal('user', JSON.stringify(user));

                this.$router.push('/preparate-para-selfie');
              } else {
                this.$router.push('/registro-de-documento');
              }
            }.bind(this)).catch(function (error) {
              let errors = error.response.data.errors;
              console.log('errors: ', error);
              let message = '';
              for (var [key, value] of Object.entries(errors)) {
                console.log(key + ' : ' + value);
                message = message + value + '<br>';
              }
              this.snackbarMessage = message;
              this.snackbar = true;
              this.loading = false;

            }.bind(this))
      }*/

    },
    resetPassword() {

      this.loading = true;

      api.post(constants.endPoints.resetPassword, {
        email: this.changeEmail,
        url_origin: location.host
      }).then(function (response) {

        console.log('reset password: ', response);
        this.loading = false;

        this.dialogResetPassword = false
        this.snackbarMessage = response.data.message;
        this.snackbar = true;

      }.bind(this)).catch(function (error) {
        let errors = error.response.data;
        console.log('errors: ', error, error.response);
        let message = '';
        for (var [key, value] of Object.entries(errors)) {
          console.log(key + ' : ' + value);
          message = message + value + '<br>';
        }
        this.snackbarMessage = 'Hubo un problema, intentalo más tarde nuevamente';
        this.snackbar = true;
        this.loading = false;

        this.$refs['password'].error = true;
        this.$refs['password'].errorMessages = ['La contraseña es incorrecta'];

      }.bind(this))

    },
  },
  mounted() {

  },
  created() {
    console.log(location.host)
  }
}
</script>

<style >

.v-messages__wrapper {
  float: right;
}

.v-application .text-caption {
  letter-spacing: 0 !important;
  line-height: 0.8rem !important;
  font-size: 0.65rem !important;
}

.terms-link {
  display: block;
}

.v-card__title {
  word-break: inherit;
}

.home-title {
  font-size: 2.5em;
  line-height: 1;
}

.card-subtitle {
  font-size: 22px;
  line-height: 1;
}

.home-image {
  height: 100px;
}

@media (max-width: 600px) {
  .home-title {
    font-size: 2em;
    line-height: 1;
  }

  .card-subtitle {
    font-size: 18px;
    line-height: 1;
  }

  .home-image {
    height: 140px;
  }
}
</style>
