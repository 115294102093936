<template>
  <v-app>
<!--    <v-app-bar
      app
      color="grey lighten-3"
      dark
      elevate-on-scroll
    >
      <v-img
          alt="Aliados QR"
          class="shrink mr-2 text-center"
          contain
          src="@/assets/logo-horizontal.png"
          transition="scale-transition"
          width="160"
      />

    </v-app-bar>-->

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>

.v-dialog {
  border-radius: 10px !important;
}

.v-text-field--outlined {
  border-radius: 6px !important;
}

.v-text-field--outlined fieldset {
  border-color: #FF9800 !important;
}

.theme--light.v-label {
  color: #FF9800 !important;
}

.v-toolbar__content {
  justify-content: center !important;
}

.wrap-text {
  white-space: normal;
  line-height: 110% !important;
}

.fill-height {
  margin-top: 0 !important;
}

</style>

<style src="@/assets/sass/theme.scss" lang="scss"/>
