import {constants} from "@/helpers/constants";

import axios from 'axios';
import {locals} from "@/helpers/locals";

let cancel = [];

axios.default.defaults.baseURL = constants.baseUrl;

export const api = {
    get,
    post,
    postFile
};

function get(endpoint, login = false) {
    return new Promise((resolve, reject) => {
        let endUrl = endpoint.split('?')[0];
        let index = cancel.findIndex((item) => {
            return item.endpoint === endUrl;
        });

        if (index > -1) {
            let reqCancel = cancel.splice(index, 1);
            reqCancel[0].source.cancel();
        }

        let request = {
            endpoint: endUrl,
            source: axios.CancelToken.source()
        };
        cancel.push(request);

        let headers = {'Content-Type': 'application/json'};
        if (login) {
            this.token = locals.getLocal('token');
            headers['Authorization'] = 'Bearer' + ' ' + this.token
        }
        axios.get(`${constants.baseUrl}${endpoint}`, {
            headers: headers,
            cancelToken: request.source.token
        }).then(value => {
            resolve(value)
        }).catch(err => {
            if (!axios.isCancel()) {
                reject(err);
            }
        });
    })
}

function post(endpoint, body, login = false) {
    let headers = {'Content-Type': 'application/json'};
    if (login) {
        this.token = locals.getLocal('token');
        headers['Authorization'] = 'Bearer' + ' ' + this.token
    }
    return axios.post(`${endpoint}`, JSON.stringify(body), {
        headers: headers
    })
}

function postFile(endpoint, body, login = false) {
    let headers = {'Content-Type': 'multipart/form-data'};
    /*if (login) {
        headers['Authorization'] = `Bearer ${auth.accessParam()}`;
    }*/

    /*axios.post(`${endpoint}`, body, {
        headers: headers
    })
    .then(function (response) {
        console.log(response);
        return response;
    })
    .catch(function (error) {
        console.log(error);
        return error;
    });*/

    return axios.post(`${endpoint}`, body, {
        headers: headers
    })
}
